<template>
  <div>
    <CrudTable
      v-bind="crudTableConfig"
      :storeModule="storeModule"
      :showAddItemsCustom="true"
      :showActionsPanel="false"
      :showSearchPanel="false"
      :showSelectPanel="false"
      :parent-key="'campaign_template'"
      :parent-value="campaignTemplateId"
      :isElevated="false"
      @addItemsEvent="onCreatePromoTemplate"
    >
      <template v-slot:[getLinkAttributes.path]="{ item }">
        <router-link :to="{ name: 'PromoTemplateDetail', params: { id: item.id }}">
          {{ Lget(item, getLinkAttributes.name, '-')}}
        </router-link>
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip v-if="showDelete" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="red" class="mr-3" @click="confirmDeletePromoTemplate(item)">fa-times</v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
        <v-tooltip v-if="showClone" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="grey" @click="onClonePromoTemplate(item)">fa-clone</v-icon>
          </template>
          <span>Duplicar</span>
        </v-tooltip>
      </template>
    </CrudTable>

    <DialogConfirmation
      v-model="showDialogConfirmation" @acceptCondition="onDeletePromoTemplate" @denyCondition="showDialogConfirmation = false"
      :titleDialog="titleDialogDelete" :textDialog="textDialogDelete" :confirm-text="confirmText" :confirm-color="confirmColor"
    />
  </div>
</template>

<script>
import Lget from 'lodash/get'
import { mapActions, mapState } from 'vuex'

import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { EventBus } from '@/apps/core/helpers/event-bus'
import DialogConfirmation from '@/lib/uncrudtable/components/forms/DialogConfirmation'
import { createSuccessNotification, createWarningNotification } from '@/lib/unnotificationsqueue'

export default {
  name: 'PromoTemplatesTable',

  components: { DialogConfirmation, CrudTable },

  props: {
    value: {
      type: Object,
      default: () => {}
    },
    showClone: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      storeModule: 'promoTemplates',
      showDialogConfirmation: false,
      selectedPromoTemplate: null,
      titleDialogDelete: 'Eliminar Promo Template',
      textDialogDelete: '¿Está seguro de que desea eliminar esta Promo Template?',
      confirmText: 'Sí, eliminar',
      confirmColor: 'red'
    }
  },

  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    }),
    ...mapState('promoTemplates', ['headers', 'filterItems', 'customActions', 'getActionPath', 'stickyColumns', 'itemsPerPage']),
    ...mapState('campaignTemplates', { currentCampaignTemplate: 'currentItem' }),
    campaignTemplateId () {
      return this.value.id.toString()
    },
    getLinkAttributes () {
      const linkAttributes = {}
      let value = this.crudTableConfig.headers?.length > 0 ? this.crudTableConfig.headers[0].value : ''

      linkAttributes['path'] = `item.${value}`
      linkAttributes['name'] = value
      return linkAttributes
    }
  },

  methods: {
    Lget,
    ...mapActions({ addNotification: 'addNotification' }),
    ...mapActions('promoTemplates', ['cloneItem', 'reloadItemList', 'deleteItem']),
    onCreatePromoTemplate () {
      EventBus.$emit('new-promo-template')
    },
    async onClonePromoTemplate (item) {
      await this.cloneItem(item.id)
      this.reloadItemList()
    },
    confirmDeletePromoTemplate (promoTemplate) {
      this.showDialogConfirmation = true
      this.selectedPromoTemplate = promoTemplate.id
    },
    async onDeletePromoTemplate () {
      try {
        await this.deleteItem(this.selectedPromoTemplate)
        this.addNotification(createSuccessNotification('Eliminada correctamente'))
        this.reloadItemList()
      } catch (error) {
        if (error.response.status === 403) {
          this.addNotification(createWarningNotification('Lo siento, no tienes permisos para eliminar promo templates'))
        }
      } finally {
        this.dialog = false
        this.selectedPromoTemplate = null
      }
    }
  }
}
</script>
