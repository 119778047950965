var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CrudTable',_vm._b({attrs:{"storeModule":_vm.storeModule,"showAddItemsCustom":true,"showActionsPanel":false,"showSearchPanel":false,"showSelectPanel":false,"parent-key":'campaign_template',"parent-value":_vm.campaignTemplateId,"isElevated":false},on:{"addItemsEvent":_vm.onCreatePromoTemplate},scopedSlots:_vm._u([{key:_vm.getLinkAttributes.path,fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'PromoTemplateDetail', params: { id: item.id }}}},[_vm._v(" "+_vm._s(_vm.Lget(item, _vm.getLinkAttributes.name, '-'))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.showDelete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",attrs:{"color":"red"},on:{"click":function($event){return _vm.confirmDeletePromoTemplate(item)}}},on),[_vm._v("fa-times")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e(),(_vm.showClone)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey"},on:{"click":function($event){return _vm.onClonePromoTemplate(item)}}},on),[_vm._v("fa-clone")])]}}],null,true)},[_c('span',[_vm._v("Duplicar")])]):_vm._e()]}}],null,true)},'CrudTable',_vm.crudTableConfig,false)),_c('DialogConfirmation',{attrs:{"titleDialog":_vm.titleDialogDelete,"textDialog":_vm.textDialogDelete,"confirm-text":_vm.confirmText,"confirm-color":_vm.confirmColor},on:{"acceptCondition":_vm.onDeletePromoTemplate,"denyCondition":function($event){_vm.showDialogConfirmation = false}},model:{value:(_vm.showDialogConfirmation),callback:function ($$v) {_vm.showDialogConfirmation=$$v},expression:"showDialogConfirmation"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }