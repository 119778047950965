import moment from 'moment'
import apiClient from '@/lib/unlogin/store/apiclient'
import CampaignDatesRangeView from '@/apps/dido/components/forms/CampaignDatesRangeView/CampaignDatesRangeView.vue'
import { validationMixin } from '@/apps/core/mixins/validations'
import { dateToDateRange } from '@/apps/core/helpers/utils'

export default {
  name: 'PnGCampaignDatesRangeView',
  components: { CampaignDatesRangeView },
  mixins: [ validationMixin ],
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    layout: {
      type: Object,
      description: 'Object defining elements to show and its layout size' +
        '{ "mainDatePrepend": 8, "mainDate": 4, "secondaryDate": 4, "subtractDays": 8 }',
      default: () => ({ mainDate: 4 })
    },
    isPngCampaign: {
      type: Boolean,
      description: 'true for "Campaign", false for "CampaignTemplate" (Must be changed to a string for new views)' +
        'Used to render either appended-fortnight or prepended fiscal-year-half and execute respective logic',
      required: true
    },
    mainDateName: {
      type: String,
      description: 'model field name for the main date (normally, store_promo_date)',
      default: 'store_promo_date'
    },
    secondaryDateName: {
      type: String,
      description: 'model field name for the secondary date (normally, order_date or service_date)',
      default: 'order_date'
    }
  },
  data () {
    return {
      loading: false,
      fiscalYear: null,
      fiscalYearHalf: null,
      fortnight: null,
      fiscalYearOptions: [],
      fiscalYearHalfOptions: [],
      fortnightOptions: [],
      fiscalYearHalfOptionsUrl: '/custom_fields/choices/52/',
      fortnightOptionsUrl: '/custom_fields/choices/68/'
    }
  },
  computed: {
    isFromCalendar () {
      return this.$route.query.date && this.$route.query['id_client']
    },

    /**
   * Returns corresponding fiscal-year's choices URL for Campaign and CampaignTemplate
   * @returns {string}
   */
    fiscalYearOptionsUrl () {
      return this.isPngCampaign ? '/custom_fields/choices/81/' : '/custom_fields/choices/82/'
    },

    /**
   * Parses respective component fields into 'prop__' attributes
   * @returns {Object}
   */
    componentProps () {
      let props = { prop__fiscal_year: this.fiscalYear }
      if (!this.isPngCampaign) {
        props['prop__fiscal_year_half'] = this.fiscalYearHalf
      } else {
        props['prop__fortnight'] = this.fortnight
      }
      return props
    }
  },
  async mounted () {
    this.loading = true
    this.isPngCampaign ? await this.loadCampaignOptions() : await this.loadCampaignTemplateOptions()
    this.loading = false
  },
  methods: {
    /**
   * mounted configuration for CampaignDetail view: api-calls and variable setting
   * @returns {Promise<void>}
   */
    async loadCampaignOptions () {
      await this.loadOptions(this.fortnightOptionsUrl, 'fortnight', 'fortnight')
      this.fortnight = this.value.prop__fortnight || null
      if (this.isFromCalendar) {
        const storePromoDate = JSON.parse(this.value.store_promo_date)
        await this.recalculateFortnight(storePromoDate)
        await this.recalculateFiscalYear(storePromoDate)
        this.$emit('input', { ...this.value, ...this.componentProps })
      }
      this.$watch(`value.${this.mainDateName}`, this.recalculateFortnightAndFiscalYear, { deep: true })
    },

    /**
   * mounted configuration for CampaignTemplateDetail view: api-calls and variable setting
   * @returns {Promise<void>}
   */
    async loadCampaignTemplateOptions () {
      await this.loadOptions(this.fiscalYearHalfOptionsUrl, 'fiscalYearHalf', 'fiscal_year_half')
      this.fiscalYearHalf = this.value.prop__fiscal_year_half || this.fiscalYearHalfOptions[0].key
      this.$watch('fiscalYear', this.recalculateMainDate)
      this.$watch('fiscalYearHalf', this.recalculateMainDate)
      if (!this.value[this.mainDateName]) {
        this.recalculateMainDate()
      }
    },

    /**
   * Parameterized options api calls from the "mounted" functions common to any detail view
   * @param optionUrl
   * @param option
   * @param optionHyphened
   * @returns {Promise<void>}
   */
    async loadOptions (optionUrl, option, optionHyphened) {
      let responses = await Promise.all([
        apiClient.get(this.fiscalYearOptionsUrl),
        apiClient.get(optionUrl)
      ])
      this.fiscalYearOptions = responses[0].data.results
      this[`${option}Options`] = responses[1].data.results
      this.fiscalYear = this.value.prop__fiscal_year || this.fiscalYearOptions[0].key
    },

    /**
   * Campaign: recalculates fortnight and fiscal-year prop according to main date (store-promo-date)
   */
    recalculateFortnightAndFiscalYear (newMainDate, oldMainDate) {
      if (newMainDate !== oldMainDate) {
        let mainDate = JSON.parse(this.value[this.mainDateName])
        if (mainDate && mainDate.lower && mainDate.upper) {
          this.recalculateFortnight(mainDate)
          this.recalculateFiscalYear(mainDate)
          this.$emit('input', { 'prop__fortnight': this.fortnight, 'prop__fiscal_year': this.fiscalYear })
        }
      }
    },

    /**
   * First part of "recalculateFortnightAndFiscalYear". recalculates fortnight for subsequent $emit
   * @param mainDate
   */
    recalculateFortnight (mainDate) {
      let monthLower = mainDate.lower.split('-')[1]
      let monthUpper = mainDate.upper.split('-')[1]
      if (monthLower === monthUpper) {
        let sum = (Number(mainDate.lower.split('-')[2]) + Number(mainDate.upper.split('-')[2]))
        this.fortnight = (sum > moment(mainDate.upper).daysInMonth()) ? this.fortnightOptions[1].key : this.fortnightOptions[0].key
      } else if (Math.abs(moment(mainDate.upper).diff(moment(mainDate.lower), 'months', false)) <= 1) {
        let daysLower = moment(mainDate.lower).daysInMonth() - Number(mainDate.lower.split('-')[2]) + 1
        let daysUpper = Number(mainDate.upper.split('-')[2])
        this.fortnight = (daysLower > daysUpper) ? this.fortnightOptions[1].key : this.fortnightOptions[0].key
      }
    },

    /**
   * Second part of "recalculateFortnightAndFiscalYear". recalculates fiscalYear (from lower date) for subsequent $emit
   * @param mainDate
   */
    recalculateFiscalYear (mainDate) {
      let fiscalYear = null
      let year = mainDate.lower.substr(0, 4)
      let date = mainDate.lower.substr(5)
      if (this.fiscalYearOptions[0].key.split('-').length === 1) {
        fiscalYear = this.fiscalYearOptions.find(fiscalYear => fiscalYear.key === year)
      } else {
        let { H1, H2 } = this.value.fiscal_year_ranges
        let fiscalYearHalf = ((H1.start <= date && (H2.start > date || H2.start < H1.start)) || (H2.start < H1.start && H2.start > date)) ? H1 : H2
        fiscalYear = this.fiscalYearOptions.find(fiscalYear => {
          return (fiscalYearHalf === H1) ? fiscalYear.key.substr(0, 4) === year : fiscalYear.key.substr(5) === year
        })
      }
      this.fiscalYear = fiscalYear ? fiscalYear.key : this.fiscalYear
    },

    /**
   * CampaignTemplate: recalculates main date according to fiscal-year and fiscal-year-half
   */
    recalculateMainDate () {
      this.$emit('input', { [`${this.mainDateName}`]: this.fiscalYearHalfDates() })
    },

    /**
   * Returns start and end dates to the fiscal year & half selected according to client's fiscal year ranges
   * @returns {string}
   */
    fiscalYearHalfDates () {
      if (!this.fiscalYear) {
        return dateToDateRange()
      }
      let ranges = this.value.fiscal_year_ranges
      let { start: dateStart, end: dateEnd } = ranges[this.fiscalYearHalf]
      let yearsInFiscalYear = this.fiscalYear.split('-')

      let yearStart = (yearsInFiscalYear.length < 1 || this.fiscalYearHalf === 'H1') ? yearsInFiscalYear[0]
        : (dateStart < ranges['H1'].start) ? yearsInFiscalYear[1] : yearsInFiscalYear[0]

      let yearEnd = (yearsInFiscalYear.length < 1 || (this.fiscalYearHalf === 'H1' && dateStart < dateEnd))
        ? yearsInFiscalYear[0] : yearsInFiscalYear[1]

      return dateToDateRange(`${yearStart}-${dateStart}`, `${yearEnd}-${dateEnd}`)
    },

    /**
   * Event handler for date changes from CampaignDatesRangeView.
   * Redirects event with received dates and its own componentProps (fiscal-year, fortnight)
   * @param event (Object): contains changed dates.
   */
    onChangeDates (event) {
      let eventWithProps = { ...this.value, ...this.componentProps }
      eventWithProps[this.mainDateName] = event[this.mainDateName]
      let secondaryDate = event[this.secondaryDateName] && JSON.parse(event[this.secondaryDateName])
      if (secondaryDate) {
        eventWithProps[this.secondaryDateName] = event[this.secondaryDateName]
      }
      this.$emit('input', eventWithProps)
    }
  }
}
