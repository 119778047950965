<template>
  <div>
  <Layout :top-bar-title="title" :activeBackButton="false" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <CustomLayout
      :value="currentItem"
      @input="updateItem"
      :loading="loading"
      :title="getTitle"
      :breadcrumbs="currentItem.campaign_template_items_breadcrumbs"
      :admin-url="currentItem.admin_url"
      :custom-view-show="isCurrentItemLoaded"
      :custom-view-name="customViewName"
      :custom-options="customViewOptions"
      :custom-component-catalog="customComponentCatalog"
      :actions="campaignTemplateActions"
      :action-selected.sync="actionSelected"
      ref="custom"
    ></CustomLayout>

    <DialogConfirmation
            v-model="showConfirmationDialog"
            v-bind="confirmationProps"
            cancelOption
    ></DialogConfirmation>
  </Layout>
   <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { dateToDateRangeString } from '@/apps/core/helpers/utils'
import sections from './defaultNavigationDrawerSections'
import CustomLayout from '@/apps/core/components/CustomLayout'
import Layout from '@/apps/core/components/Layout'
import { BASE_URL, RESPONSE_LEVEL } from '@/variables'
import PnGCampaignDatesRangeView from '@/apps/dido/components/forms/PnGCampaignDatesRangeView/PnGCampaignDatesRangeView.vue'
import HierarchiesFiltersSelectorWrapper from '@/apps/core/components/HierarchiesFiltersSelectorWrapper/HierarchiesFiltersSelectorWrapper.vue'
import CampaignDatesRangeView from '@/apps/dido/components/forms/CampaignDatesRangeView/CampaignDatesRangeView.vue'
import PromoTemplatesTable from '@/apps/dido/components/tables/PromoTemplatesTable'
import ClientFiltersTable from '@/apps/dido/components/tables/ClientFiltersTable'
import { createNotification } from '@/lib/unnotificationsqueue'
import { EventBus } from '@/apps/core/helpers/event-bus'
import DialogConfirmation from '@/lib/uncrudtable/components/forms/DialogConfirmation'
import ConfirmDialog from '@/apps/core/components/ConfirmDialogAlt.vue'
import { isEqual } from 'lodash'

export default {
  name: 'CampaignTemplateDetail',
  components: { Layout, CustomLayout, DialogConfirmation, ConfirmDialog },
  data () {
    return {
      isCurrentItemLoaded: false,
      loading: false,
      showConfirmationDialog: false,
      actionSelected: {},
      sections: sections,
      customViewName: 'campaign_template_detail',
      customComponentCatalog: {
        'PnGCampaignDatesRangeView': PnGCampaignDatesRangeView,
        'CampaignDatesRangeView': CampaignDatesRangeView,
        'PromoTemplatesTable': PromoTemplatesTable,
        'ClientFiltersTable': ClientFiltersTable,
        'HierarchiesFiltersSelectorWrapper': HierarchiesFiltersSelectorWrapper
      },
      storePromoDateQuery: this.$route.query.date,
      confirmationProps: {
        titleDialog: this.$t('dialogs.creatingCampaign'),
        textDialog: this.$t('dialogs.redirectingToCampaign'),
        showButtons: false,
        loading: true
      },
      staticOldItem: null,
      countChanges: 0,
      isSaving: false
    }
  },
  computed: {
    ...mapState('campaignTemplates', {
      storeLoading: 'loading',
      currentItem: 'currentItem'
    }),
    ...mapState('promoTemplates', {
      promoTemplateItems: 'items'
    }),

    campaignHasChanged () {
      if (this.currentItem !== {} && this.staticOldItem) {
        return !isEqual(this.currentItem, this.staticOldItem)
      } else {
        return false
      }
    },

    customForm () {
      return this.$refs.custom && this.$refs.custom.$refs.form
    },
    createCampaignFromTemplateAction () {
      return {
        label: this.$t('campaigns.campaignTemplateCreateCampaign'),
        method: this.createCampaignFromTemplate,
        args: { redirect: false },
        validate: true,
        icon: 'fa-clone',
        color: 'primary'
      }
    },
    campaignTemplateActions: function () {
      return [
        {
          label: this.$t('general.delete'),
          method: this.onDelete,
          confirm: true,
          confirmOptions: { confirmText: this.$t('dialogs.confirmOption'), confirmColor: 'red' },
          icon: 'fa-times',
          color: 'red',
          dark: true,
          floatEnd: true,
          showButton: this.currentItem.can_edit
        },
        {
          label: this.$t('campaigns.campaignTemplateCreateCampaign'),
          method: this.createCampaignFromTemplate,
          args: { redirect: false },
          validate: this.currentItem.can_edit,
          icon: 'fa-clone',
          color: 'secondary',
          showButton: typeof this.currentItem.can_create_campaign_from_template !== 'undefined'
            ? this.currentItem.can_create_campaign_from_template : false
        },
        {
          label: this.$t('customActions.saveAndContinue'),
          method: this.saveForm,
          args: { redirect: false },
          validate: true,
          icon: 'fa-save',
          color: 'primary',
          showButton: this.currentItem.can_edit
        },
        {
          label: this.$t('general.save'),
          method: this.saveForm,
          args: { redirect: true },
          validate: true,
          icon: 'fa-save',
          color: 'primary',
          showButton: this.currentItem.can_edit
        }
      ]
    },
    customViewOptions: function () {
      return {
        context: {
          campaignId: this.$router.currentRoute.params.id,
          serverUrl: BASE_URL
        }
      }
    },
    title: function () {
      return this.storeLoading ? this.$t('campaigns.campaignTemplateLoading')
        : this.currentItem ? this.$t('campaigns.campaignTemplate', { description: this.currentItem.description || '' })
          : this.$t('campaigns.campaignTemplateNotFound')
    },
    getTitle () {
      return this.currentItem.description || this.$t('campaigns.campaignTemplateNew')
    }
  },
  watch: {
    currentItem: {
      deep: true,
      handler () {
        if (this.currentItem && this.currentItem.active && this.countChanges < 2) {
          this.staticOldItem = this.currentItem
          this.countChanges++
        }
      }
    }
  },
  async created () {
    await this.getItem(this.$router.currentRoute.params.id)
    this.isCurrentItemLoaded = true
    this.setQueryInCurrentItem()
  },
  mounted () {
    EventBus.$on('new-promo-template', this.onCreatePromoTemplate)
  },
  beforeDestroy () {
    this.dismissNotifications()
    EventBus.$off('new-promo-template')
  },
  methods: {
    ...mapActions({ addNotification: 'addNotification', dismissNotifications: 'dismissNotifications' }),
    ...mapActions('campaignTemplates', ['getItem', 'patchItem', 'deleteItem', 'updateCurrentItem']),
    ...mapActions('promoTemplates', ['createItemFromPk']),
    ...mapActions('campaignTemplates', ['createCampaignFromTemplateId']),
    ...mapActions('promos', ['newPromoFromTemplate']),

    updateItem (items) {
      this.updateCurrentItem({ ...this.currentItem, ...items })
    },
    setQueryInCurrentItem () {
      if (this.storePromoDateQuery && !this.currentItem.store_promo_date) {
        let date = dateToDateRangeString(this.storePromoDateQuery)
        this.updateCurrentItem({
          ...this.currentItem,
          store_promo_date: date
        })
      }
    },
    async onCreatePromoTemplate () {
      this.loading = true
      try {
        const data = await this.createItemFromPk({ campaign_template: this.currentItem.id })
        await this.$router.push({
          name: 'PromoTemplateDetail',
          params: { id: data.id }
        })
      } finally {
        this.loading = false
      }
    },
    async saveForm ({ redirect, reload = true }) {
      this.loading = true
      this.isSaving = true
      try {
        let response = await this.patchItem(this.currentItem.id)
        if (response.level === RESPONSE_LEVEL.SUCCESS) {
          if (redirect) {
            this.$router.push({ name: 'CampaignTemplateList' })
          } else {
            this.addNotification(createNotification(response.message, RESPONSE_LEVEL.SUCCESS))
            if (reload) this.$router.go(0)
            return true
          }
        } else {
          this.addNotification(createNotification(response.message, response.level))
          return false
        }
      } finally {
        this.loading = false
        this.isSaving = false
      }
    },
    async onDelete () {
      this.loading = true
      let response = await this.deleteItem(this.currentItem.id)
      if (response.level === RESPONSE_LEVEL.SUCCESS) {
        await this.$router.push({ name: 'CampaignTemplateList' })
      } else {
        this.addNotification(createNotification(response.message, response.level))
      }
      this.loading = false
    },
    async createCampaignFromTemplate () {
      this.loading = true
      const templateSaved = await this.saveForm({ redirect: false, reload: false })
      if (!this.currentItem.can_edit || templateSaved) {
        this.loading = true
        try {
          let response = await this.createCampaignFromTemplateId(this.currentItem.id)
          this.showConfirmationDialog = true
          this.addNotification(createNotification(response.message, response.level))
          const redirect = response.redirect_route_name
          const campaignId = response.redirect_route_url_params && response.redirect_route_url_params['id']
          let route = this.$router.resolve({ name: redirect, params: { id: campaignId } })
          window.open(route.href, '_blank')
          location.reload()
        } catch (error) {
          this.addNotification(createNotification(error.message, error.level))
        } finally {
          this.showConfirmationDialog = false
          this.loading = false
        }
      }
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (this.campaignHasChanged && !this.isSaving) {
      if (await this.$refs.confirm.open(this.$t('general.warning'), this.$t('dialogs.pendingChanges'))) {
        if (this.customForm.validate()) {
          const res = await this.patchItem(this.currentItem.id)
          if (res.level === RESPONSE_LEVEL.SUCCESS) {
            next()
          } else {
            next(false)
            this.addNotification(createNotification(res.message, res.level))
          }
        } else {
          next(false)
          this.addNotification(createNotification(this.$t('dialogs.errorFormData'), RESPONSE_LEVEL.ERROR))
        }
      } else {
        next()
      }
    } else {
      next()
    }
  }
}
</script>
