import moment from 'moment'
import Lset from 'lodash/set'
import { IS_PNG, IS_CAC } from '@/variables'
import { dateToDateRangeString } from '@/apps/core/helpers/utils'
import DateRangePickerInField from '@/apps/core/components/forms/DateRangePickerInField/DateRangePickerInField.vue'

export default {
  name: 'CampaignDatesRangeView',
  components: { DateRangePickerInField },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    layout: {
      type: Object,
      description: 'Object defining elements to show and its layout size' +
        '{ "mainDatePrepend": 8, "mainDate": 4, "secondaryDate": 4, "subtractDays": 8 }',
      default: () => ({ mainDate: 12 })
    },
    mainDateName: {
      type: String,
      description: 'model field name for the main date (normally, store_promo_date)',
      default: 'store_promo_date'
    },
    secondaryDateName: {
      type: String,
      description: 'model field name for the secondary date (normally, order_date or service_date)',
      default: 'order_date'
    },
    hasSecondaryDate: {
      type: Boolean,
      description: 'When true, used to recalculate secondary date (PENDING IN SCH AND LOR)',
      default: false
    }
  },
  data () {
    return {
      subtractDays: [ 7, 14, 28, 42 ],
      lower: 'lower',
      upper: 'upper',
      DATE_FORMAT_UPDATE: 'YYYY-MM-DD'
    }
  },
  computed: {
    /**
     * Reactive getter/setter for mainDate's start value
     */
    startMainDate: {
      get () {
        return this.getDateByCurrentItem(this.mainDateName, this.lower)
      },
      set (value) {
        const date = this.setDateByCurrentItem(this.mainDateName, this.lower, value)
        let emitItem = { ...this.value }
        emitItem[this.mainDateName] = JSON.stringify(date)
        this.$emit('input', emitItem)
      }
    },

    /**
     * Reactive getter/setter for mainDate's end value
     */
    endMainDate: {
      get () {
        return this.getDateByCurrentItem(this.mainDateName, this.upper)
      },
      set (value) {
        const date = this.setDateByCurrentItem(this.mainDateName, this.upper, value)
        if (value === '') return
        this.$emit('input', this.updateMainAndSecondaryDate(date))
      }
    },

    /**
     * Reactive getter/setter for secondaryDate's start value
     */
    startSecondaryDate: {
      get () {
        return this.getDateByCurrentItem(this.secondaryDateName, this.lower)
      },
      set (value) {
        const date = this.setDateByCurrentItem(this.secondaryDateName, this.lower, value)
        let emitItem = { ...this.value }
        emitItem[this.secondaryDateName] = JSON.stringify(date)
        this.$emit('input', emitItem)
      }
    },

    /**
     * Reactive getter/setter for secondaryDate's end value
     */
    endSecondaryDate: {
      get () {
        return this.getDateByCurrentItem(this.secondaryDateName, this.upper)
      },
      set (value) {
        const date = this.setDateByCurrentItem(this.secondaryDateName, this.upper, value)
        if (value === '') return
        let emitItem = { ...this.value }
        emitItem[this.secondaryDateName] = JSON.stringify(date)
        this.$emit('input', emitItem)
      }
    },

    /**
     * Sets empty date (?), useful when value's date is null/undefined
     * @returns {{lower: string, upper: string, bounds: string}}
     */
    currentDate () {
      return { 'bounds': '[)', 'lower': '', 'upper': '' }
    },

    isMainDateNull () {
      let date = this.value[this.mainDateName]
      return !(date && (JSON.parse(date).lower || JSON.parse(date).upper))
    },

    isSecondaryDateNull () {
      let date = this.value[this.secondaryDateName]
      return !(date && (JSON.parse(date).lower || JSON.parse(date).upper))
    },

    /**
     * Add 0 if is png to reset the dates range selected.
     * @returns {number[]}
     */
    subtractDaysButtons () {
      if (IS_PNG) {
        this.subtractDays.unshift(0)
      }
      return this.subtractDays
    },

    /**
     * Gets client's label name for the main date (Normally store_promo_date)
     * @returns {string}
     */
    labelMainDate () {
      return `* ${this.value.dates_verbose_name[this.mainDateName]}`
    },

    /**
     * Gets client's label name for the main date (Normally order_date or service_date)
     * @returns {string}
     */
    labelSecondaryDate () {
      return `* ${this.value.dates_verbose_name[this.secondaryDateName]}`
    }

  },
  mounted () {
    const startDate = this.$route.query?.date
    if (startDate) {
      let date = dateToDateRangeString(startDate)
      this.$emit('input', this.updateMainAndSecondaryDate(JSON.parse(date)))
    }
  },
  methods: {
    /**
   * Used by getters to get the respective date value or a default empty value
   * @param keyCurrentItem
   * @param key
   * @returns {*}
   */
    getDateByCurrentItem (keyCurrentItem, key) {
      let date
      if (this.value[keyCurrentItem]) {
        date = JSON.parse(this.value[keyCurrentItem])
      } else {
        date = this.currentDate
      }
      return this.Lget(date, key)
    },

    /**
   * Used by setters to set the respective date value or a default empty value
   * @param keyCurrentItem
   * @param key
   * @param value
   * @returns {any | {lower: string, upper: string, bounds: string}}
   */
    setDateByCurrentItem (keyCurrentItem, key, value) {
      const date = this.value[keyCurrentItem] ? JSON.parse(this.value[keyCurrentItem]) : this.currentDate
      let updateDate = date
      if (key === this.lower) {
        updateDate = Lset(date, key, value)
        updateDate = Lset(updateDate, this.upper, '')
      } else if (key === this.upper) {
        let lowerDate = this.Lget(date, this.lower)

        if (!value) {
          updateDate = Lset(updateDate, this.lower, '')
          updateDate = Lset(updateDate, this.upper, '')
        } else if (lowerDate > value) {
          updateDate = Lset(updateDate, this.lower, value)
          updateDate = Lset(updateDate, this.upper, lowerDate)
        } else {
          updateDate = Lset(updateDate, this.lower, lowerDate)
          updateDate = Lset(updateDate, this.upper, value)
        }
      }
      return updateDate
    },

    /**
   * Event handler for "subtractDays" buttons: sets secondaryDate values a given amount of days before mainDate's
   * @param days {number} Amount of days to set secondaryDate before mainDate
   */
    subtractDaysInSecondaryDate (days, date) {
      if (this.isMainDateNull) return

      const currentSecondaryDateLower = !date ? this.Lget(JSON.parse(this.value[this.mainDateName]), this.lower) : this.Lget(date, this.lower)
      const currentSecondaryDateUpper = !date ? this.Lget(JSON.parse(this.value[this.mainDateName]), this.upper) : this.Lget(date, this.upper)
      const subtractSecondaryDateLower = moment(currentSecondaryDateLower).subtract(days, 'days').format(this.DATE_FORMAT_UPDATE)
      const subtractSecondaryDateUpper = moment(currentSecondaryDateUpper).subtract(days, 'days').format(this.DATE_FORMAT_UPDATE)

      const secondaryDate = JSON.parse(this.value[this.secondaryDateName]) || this.currentDate
      const dateLower = Lset(secondaryDate, this.lower, subtractSecondaryDateLower)
      const dateUpper = Lset(dateLower, this.upper, subtractSecondaryDateUpper)
      let emitItem = { ...this.value }
      emitItem[this.secondaryDateName] = JSON.stringify(dateUpper)
      if (!date) {
        this.$emit('input', emitItem)
      } else {
        return emitItem[this.secondaryDateName]
      }
    },

    /**
   * When setting mainDate, secondaryDate is also set to the same values
   * @param date
   */
    updateMainAndSecondaryDate (date) {
      let argsDate = { ...this.value }
      argsDate[this.mainDateName] = JSON.stringify(date)
      if (date.lower && date.upper && this.hasSecondaryDate) {
        if (IS_PNG) {
          argsDate[this.secondaryDateName] = this.subtractDaysInSecondaryDate(0, date)
        } else if (this.isSecondaryDateNull && !IS_CAC) {
          argsDate[this.secondaryDateName] = argsDate[this.mainDateName]
        }
      }
      return argsDate
    }
  }
}
